import axios from "axios";

// const api_url =
//   process.env.NODE_ENV === "production"
//     ? "http://login.emerge.lk:9090/MyPorterAPI/api/"
//     : "http://login.emerge.lk:9090/MyPorterAPI/api/";

const API_URL = "http://codebrix-sys.xyz:7031/api/v1/";
// const API_URL = "https://localhost:44391/api/v1/";
// const API_URL = "http://admin.myportersa.com/myporteriis1/api/v1/";

class LuggageService {
  getLuggageDetails() {
    return axios.get(API_URL + "luggage/luggage-types");
  }

  getHandlingInstructions() {
    return axios.get(API_URL + "handling-instruction");
  }

  getValidationEnhancement() {
    return axios.get(API_URL + "package-validation?countryID=1");
  }

  getAddtionalServices() {
    return axios.get(API_URL + "addtional-services");
  }
  // getValidationEnhancement() {
  //   return axios.get(
  //     "http://login.emerge.lk:9090/MyPorterAPI/api/Validation/GetPackageValidations?countryID=1"
  //   );
  // }
}
export default new LuggageService();
