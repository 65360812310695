import axios from "axios";

const API_URL = "http://codebrix-sys.xyz:7031";
// const API_URL = "https://localhost:44391";

class InvoiceBookingDetailsService {
  getInvoiceBookingDetails(invoiceBookingId) {
    return axios.get(
      API_URL +
        "/api/v1/booking-requests/history-by-booking/" +
        invoiceBookingId
    );
  }

  sendInvoicePDF(data) {
    return axios.post(
      API_URL + "/api/v1/system-user-booking/send-invoice-email",
      data
    );
  }
}

export default new InvoiceBookingDetailsService();
